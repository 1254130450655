<template>
  <div>
    <label
      class="text-left block"
      :class="
        isBig ? 'font-sans text-sm md:text-base font-medium' : 'list-label'
      "
    >
      <span v-if="label" class="ml-4">
        {{ `${$t(label)}*` }}
      </span>

      <div
        class="flex items-center border focus-within:outline-none focus-within:ring rounded-full bg-[#ffffff]"
        :class="{
          'border-red': validity !== 'valid',
          'mt-2': isBig,
          'mt-1': !isBig,
        }"
      >
        <input
          name="password"
          v-model="modelValue"
          class="w-full placeholder-opacity-50 input-text appearance-none text-black !font-medium px-4 rounded-full focus:outline-none"
          :class="{
            'py-4': isBig,
            'py-2': !isBig,
          }"
          v-bind="$attrs"
          :type="type"
          required
          :pattern="pattern ? pattern : undefined"
          :minlength="minlength ? minlength : undefined"
          :maxlength="maxlength"
          :placeholder="$t(placeholder)"
          :data-testid="dataTestid"
          @blur="validateInput"
        />

        <span
          class="flex justify-end items-center gap-1 text-blue body-xs px-2 cursor-pointer"
          @click="toggleVisibility"
        >
          {{ type === 'password' ? $t('Show') : $t('Hide') }}

          <CommonFontAwesomeIcon
            class="mr-1"
            :icon="
              type === 'password' ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'
            "
          />
        </span>
      </div>
    </label>
    <template v-if="validity !== 'valid'">
      <p
        v-if="validity === 'patternMismatch'"
        tag="p"
        class="list-label text-red text-right mt-1"
      >
        {{ $t('passwordRequirements') }}
      </p>
      <i18n-t
        v-else
        :keypath="'formErrors.' + validity"
        tag="p"
        scope="global"
        class="list-label text-red text-right mt-1"
      >
        <template #field>
          {{ $t('Password') }}
        </template>
        <template #fieldLowerCase>
          {{ $t('Password').toLowerCase() }}
        </template>
        <template #minlength>
          {{ minlength }}
        </template>
        <template #maxlength>
          {{ maxlength }}
        </template>
      </i18n-t>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Validity } from './Input.vue'

withDefaults(
  defineProps<{
    placeholder?: string
    minlength?: number | false
    maxlength?: number
    pattern?: string | false
    label?: string
    isBig?: boolean
    dataTestid?: string
  }>(),
  {
    placeholder: '',
    minlength: 8,
    pattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).+',
  }
)
const modelValue = defineModel<string>({
  required: true,
})
const type = ref<'password' | 'text'>('password')
const validity = ref<Validity>('valid')

function toggleVisibility() {
  if (type.value === 'password') {
    type.value = 'text'
  } else {
    type.value = 'password'
  }
}

function validateInput(event: Event) {
  const target = event.target as HTMLInputElement
  const validityArray = Object.keys(
    Object.getPrototypeOf(target.validity)
  ) as Validity[]
  validity.value = validityArray.filter((key) => target?.validity[key])[0]
}
</script>
